<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title title is-3">Components Config</p>
      <button class="card-header-icon" aria-label="more options">
        <span class="icon">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </header>
    <div class="card-content">
      <div class="container">
        <ComponentsConfig />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ComponentsConfig from '@/components/AdminComponents/Config/ComponentConfig/ComponentsConfig.vue'

export default defineComponent({
  components: {
    ComponentsConfig,
  },
  setup() {
    return {}
  },
})
</script>
