<template>
  <div class="field">
    <label class="label">{{ title }}</label>
    <div class="columns">
      <div class="column">
        <div class="field">
          <input
            class="input"
            type="text"
            placeholder="Label"
            v-model="boxData.label"
            required
          />
        </div>
      </div>
      <div class="column" v-if="isBoxHas('placeholder')">
        <div class="field">
          <input
            class="input"
            type="text"
            placeholder="Placeholder"
            v-model="boxData.placeholder"
            required
          />
        </div>
      </div>
    </div>
    <div class="columns" v-if="isBoxHas('noResult')">
      <div class="column">
        <div class="field">
          <textarea
            class="input"
            type="text"
            placeholder="No Result"
            v-model="boxData.noResult"
            required
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { Box } from '@/types/config'

export default defineComponent({
  props: {
    title: String,
    modelValue: {
      type: Object as PropType<Box>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const boxData = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    })
    const isBoxHas = (property: 'placeholder' | 'noResult') =>
      boxData.value[property] || boxData.value[property] === ''
    return {
      boxData,
      isBoxHas,
    }
  },
})
</script>
