<template>
  <div class="box">
    <h3 class="title is-4">Encryption Tab</h3>
    <div class="field">
      <div class="field">
        <label class="label">Title</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Tab title"
            v-model="encryptTabConfig.title"
          />
        </div>
      </div>
      <div class="field">
        <BoxConfig title="Message Box" v-model="encryptTabConfig.messageBox" />
      </div>
      <div class="field">
        <label class="label">Buttons</label>
        <div class="field">
          <div class="container p-5">
            <ButtonConfig
              title="Submit Button"
              v-model="encryptTabConfig.buttons.submit"
            />
            <ButtonConfig
              title="Copy Button"
              :copy-button="true"
              class="mt-3"
              v-model="encryptTabConfig.buttons.copy"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <button
        class="button is-primary"
        @click="updateData('encryptionTab')"
        :disabled="isUpdating"
      >
        Save
      </button>
    </div>
    <hr />
    <div class="field">
      <h3 class="title is-4">Option of encryption algorithms</h3>
      <div class="field">
        <h3 class="title is-5">Hash</h3>
        <BoxConfig
          title="Output Length"
          v-model="cryptoOptionsConfig.hash.outputLengthBox"
        />
        <h3 class="title is-5">Hmac</h3>
        <BoxConfig
          title="Secret"
          v-model="cryptoOptionsConfig.hmac.secretBox"
        />
        <h3 class="title is-5">PBKDF2</h3>
        <BoxConfig title="Salt" v-model="cryptoOptionsConfig.pbkdf2.saltBox" />
        <BoxConfig
          title="Iterations"
          v-model="cryptoOptionsConfig.pbkdf2.iterationsBox"
        />
        <BoxConfig
          title="Key Size"
          v-model="cryptoOptionsConfig.pbkdf2.keysizeBox"
        />
        <h3 class="title is-5">Ciphers</h3>
        <BoxConfig
          title="Modes"
          v-model="cryptoOptionsConfig.ciphers.modesBox"
        />
        <BoxConfig
          title="Padding Schemes"
          v-model="cryptoOptionsConfig.ciphers.paddingBox"
        />
        <BoxConfig
          title="Secret"
          v-model="cryptoOptionsConfig.ciphers.secretBox"
        />
      </div>
    </div>
    <div class="field">
      <button
        class="button is-primary"
        @click="updateData('cryptoOption')"
        :disabled="isUpdating"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

import { Tab } from '@/types/config'
import ButtonConfig from './ButtonConfig.vue'
import BoxConfig from './BoxConfig.vue'

export default defineComponent({
  components: {
    ButtonConfig,
    BoxConfig,
  },
  setup(_, { emit }) {
    const store = useStore()
    const encryptTabConfig = ref(
      store.state.config.components.general.tabs.encrypt as Tab
    )
    const cryptoOptionsConfig = ref(store.state.config.components.crypto)
    const isUpdating = ref(false)
    const updateData = async (type: string) => {
      isUpdating.value = true
      const child =
        type === 'encryptionTab'
          ? 'components/general/tabs/encrypt'
          : 'components/crypto'
      const data =
        type === 'encryptionTab'
          ? encryptTabConfig.value
          : cryptoOptionsConfig.value
      await store.dispatch('updateComponentsConfig', {
        child,
        data,
      })
      emit('updated')
      isUpdating.value = false
    }
    return {
      encryptTabConfig,
      cryptoOptionsConfig,
      isUpdating,
      updateData,
    }
  },
})
</script>
