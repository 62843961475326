<template>
  <div :class="selectClass">
    <select v-model="currentColor">
      <option v-for="(color, index) in colors" :key="index" :value="color">
        {{ color }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    color: String,
  },
  setup(props, { emit }) {
    const currentColor = ref(props.color)
    const colors = ref([
      'primary',
      'link',
      'info',
      'success',
      'warning',
      'danger',
    ])
    const selectClass = computed(() => `select is-${currentColor.value}`)
    watch(currentColor, () => {
      emit('update', currentColor.value)
    })
    return {
      currentColor,
      colors,
      selectClass,
    }
  },
})
</script>
