<template>
  <div>
    <label class="label">{{ title }}</label>
    <div class="columns">
      <div class="column">
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Button Title"
            v-model="button.title"
            required
          />
        </div>
      </div>
      <div class="column" v-if="copyButton">
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Copied Text"
            v-model="button.copiedTitle"
            required
          />
        </div>
      </div>
      <div class="column">
        <div class="control">
          <ColorPicker
            :color="button.color"
            @update="(color) => (button.color = color)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import ColorPicker from '../../components/ColorPicker.vue'

export default defineComponent({
  components: {
    ColorPicker,
  },
  props: {
    title: String,
    copyButton: Boolean,
    modelValue: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const button = computed({
      get: () => props.modelValue,
      set: (data) => emit('update:modelValue', data),
    })
    return {
      button,
    }
  },
})
</script>
