
import { computed, defineComponent } from 'vue'
import ColorPicker from '../../components/ColorPicker.vue'

export default defineComponent({
  components: {
    ColorPicker,
  },
  props: {
    title: String,
    copyButton: Boolean,
    modelValue: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const button = computed({
      get: () => props.modelValue,
      set: (data) => emit('update:modelValue', data),
    })
    return {
      button,
    }
  },
})
