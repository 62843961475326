
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

import { Tab } from '@/types/config'
import ButtonConfig from './ButtonConfig.vue'
import BoxConfig from './BoxConfig.vue'

export default defineComponent({
  components: {
    ButtonConfig,
    BoxConfig,
  },
  setup(_, { emit }) {
    const store = useStore()
    const decryptTabConfig = ref(
      store.state.config.components.general.tabs.decrypt as Tab
    )
    const isUpdating = ref(false)
    const updateData = async (e: Event) => {
      e.preventDefault()
      isUpdating.value = true
      const child = 'components/general/tabs/decrypt'
      const data = decryptTabConfig.value
      await store.dispatch('updateComponentsConfig', {
        child,
        data,
      })
      emit('updated')
      isUpdating.value = false
    }
    return {
      decryptTabConfig,
      isUpdating,
      updateData,
    }
  },
})
