<template>
  <div class="tabs is-centered is-fullwidth is-large">
    <ul>
      <li
        v-for="(tab, index) in tabs"
        @click="activeTab(index)"
        :key="index"
        :class="tab.active ? 'is-active' : ''"
      >
        <a class="is-size-5">{{ tab.title }}</a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, unref } from 'vue'

interface Tab {
  title: string
  active: boolean
}

export default defineComponent({
  props: {
    tabsData: {
      type: Array as PropType<Array<Tab>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const tabs = ref(unref(props.tabsData))
    const activeTab = (tabIndex: number) => {
      tabs.value = tabs.value.map((tab, index) => {
        const active = tabIndex === index
        return { ...tab, active }
      })
      emit('update:tabs', tabs.value)
    }
    return {
      tabs,
      activeTab,
    }
  },
})
</script>
