
import { computed, defineComponent, PropType } from 'vue'
import { Box } from '@/types/config'

export default defineComponent({
  props: {
    title: String,
    modelValue: {
      type: Object as PropType<Box>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const boxData = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    })
    const isBoxHas = (property: 'placeholder' | 'noResult') =>
      boxData.value[property] || boxData.value[property] === ''
    return {
      boxData,
      isBoxHas,
    }
  },
})
