
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

import { Tab } from '@/types/config'
import ButtonConfig from './ButtonConfig.vue'
import BoxConfig from './BoxConfig.vue'

export default defineComponent({
  components: {
    ButtonConfig,
    BoxConfig,
  },
  setup(_, { emit }) {
    const store = useStore()
    const encryptTabConfig = ref(
      store.state.config.components.general.tabs.encrypt as Tab
    )
    const cryptoOptionsConfig = ref(store.state.config.components.crypto)
    const isUpdating = ref(false)
    const updateData = async (type: string) => {
      isUpdating.value = true
      const child =
        type === 'encryptionTab'
          ? 'components/general/tabs/encrypt'
          : 'components/crypto'
      const data =
        type === 'encryptionTab'
          ? encryptTabConfig.value
          : cryptoOptionsConfig.value
      await store.dispatch('updateComponentsConfig', {
        child,
        data,
      })
      emit('updated')
      isUpdating.value = false
    }
    return {
      encryptTabConfig,
      cryptoOptionsConfig,
      isUpdating,
      updateData,
    }
  },
})
