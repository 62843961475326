
import { RecentResultsTable } from '@/types/config'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup(_, { emit }) {
    const store = useStore()
    const recentTable = ref(
      store.state.config.components.general.recentTable as RecentResultsTable
    )
    const child = 'components/general/recentTable'
    const updateData = async (e: Event) => {
      e.preventDefault()
      await store.dispatch('updateComponentsConfig', {
        child,
        data: recentTable.value,
      })
      emit('updated')
    }
    return {
      recentTable,
      updateData,
    }
  },
})
