<template>
  <form class="box" @submit="updateData">
    <h3 class="title is-4">Decryption Tab</h3>
    <div class="field">
      <div class="field">
        <label class="label">Title</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Tab title"
            v-model="decryptTabConfig.title"
          />
        </div>
      </div>
      <div class="field">
        <BoxConfig title="Message Box" v-model="decryptTabConfig.messageBox" />
      </div>
      <div class="field">
        <label class="label">Buttons</label>
        <div class="field">
          <div class="container p-5">
            <ButtonConfig
              title="Submit Button"
              v-model="decryptTabConfig.buttons.submit"
            />
            <ButtonConfig
              title="Copy Button"
              :copy-button="true"
              class="mt-3"
              v-model="decryptTabConfig.buttons.copy"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <button class="button is-primary" :disabled="isUpdating" type="submit">
        Save
      </button>
    </div>
    <hr />
    <h3 class="title is-4">Result Section</h3>
    <BoxConfig title="Result" v-model="decryptTabConfig.result" />
    <div class="field">
      <button class="button is-primary" :disabled="isUpdating" type="submit">
        Save
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

import { Tab } from '@/types/config'
import ButtonConfig from './ButtonConfig.vue'
import BoxConfig from './BoxConfig.vue'

export default defineComponent({
  components: {
    ButtonConfig,
    BoxConfig,
  },
  setup(_, { emit }) {
    const store = useStore()
    const decryptTabConfig = ref(
      store.state.config.components.general.tabs.decrypt as Tab
    )
    const isUpdating = ref(false)
    const updateData = async (e: Event) => {
      e.preventDefault()
      isUpdating.value = true
      const child = 'components/general/tabs/decrypt'
      const data = decryptTabConfig.value
      await store.dispatch('updateComponentsConfig', {
        child,
        data,
      })
      emit('updated')
      isUpdating.value = false
    }
    return {
      decryptTabConfig,
      isUpdating,
      updateData,
    }
  },
})
</script>
