<template>
  <form class="field" @submit="updateData">
    <div class="field">
      <h2 class="title is-4">Table title</h2>
      <div class="control">
        <input
          class="input"
          required
          type="text"
          placeholder="Table title"
          v-model="recentTable.title"
        />
      </div>
    </div>
    <div class="field mt-3">
      <h2 class="title is-4">Columns title</h2>
      <div class="field">
        <div class="label">Text column</div>
        <div class="control">
          <input
            type="text"
            class="input"
            required
            v-model="recentTable.columns.text.title"
          />
        </div>
      </div>
      <div class="field">
        <div class="label">Result column</div>
        <div class="control">
          <input
            type="text"
            class="input"
            required
            v-model="recentTable.columns.result.title"
          />
        </div>
      </div>
      <div class="field">
        <div class="label">Created At column</div>
        <div class="control">
          <input
            type="text"
            class="input"
            required
            placeholder="Column's title"
            v-model="recentTable.columns.createdAt.title"
          />
        </div>
      </div>
    </div>
    <div class="field">
      <button class="button is-primary" type="submit">Save</button>
    </div>
  </form>
</template>

<script lang="ts">
import { RecentResultsTable } from '@/types/config'
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup(_, { emit }) {
    const store = useStore()
    const recentTable = ref(
      store.state.config.components.general.recentTable as RecentResultsTable
    )
    const child = 'components/general/recentTable'
    const updateData = async (e: Event) => {
      e.preventDefault()
      await store.dispatch('updateComponentsConfig', {
        child,
        data: recentTable.value,
      })
      emit('updated')
    }
    return {
      recentTable,
      updateData,
    }
  },
})
</script>
