<template>
  <div class="box">
    <div class="notification is-primary" v-if="isUpdated">
      <button class="delete" @click="removeNotification"></button>
      Config has been updated!
    </div>
    <Tabs
      :tabsData="tabs"
      @update:tabs="updateTabs"
      @updated="() => (isUpdated = true)"
    />
    <EncryptTab v-if="tabs[0].active" @updated="() => (isUpdated = true)" />
    <DecryptTab
      v-else-if="tabs[1].active"
      @updated="() => (isUpdated = true)"
    />
    <RecentTableTab v-else />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { ComponentsConfig } from '@/types/config'
import { useStore } from 'vuex'

import Tabs from '@/components/Tabs.vue'
import EncryptTab from './components/EncryptTab.vue'
import DecryptTab from './components/DecryptTab.vue'
import RecentTableTab from './components/RecentTableTab.vue'

export default defineComponent({
  components: {
    Tabs,
    EncryptTab,
    DecryptTab,
    RecentTableTab,
  },
  setup() {
    const store = useStore()
    const tabs = ref([
      { title: 'Encryption Component', active: true },
      { title: 'Decryption Component', active: false },
      { title: 'Recent Table Component', active: false },
    ])
    const isUpdated = ref(false)
    const removeNotification = () => (isUpdated.value = false)
    const updateTabs = (updatedTabs: any) => {
      tabs.value = updatedTabs
    }
    const componentsConfig = computed(
      () => store.state.config.components as ComponentsConfig
    )
    return {
      tabs,
      updateTabs,
      removeNotification,
      isUpdated,
      componentsConfig,
    }
  },
})
</script>
