
import { defineComponent } from 'vue'
import ComponentsConfig from '@/components/AdminComponents/Config/ComponentConfig/ComponentsConfig.vue'

export default defineComponent({
  components: {
    ComponentsConfig,
  },
  setup() {
    return {}
  },
})
